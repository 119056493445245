import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Sidebar, BlogCard, Seo } from "../components/index"
import { SEOTitle, Wrapper, Container, Row, Text, Column } from "../theme/index"
import { Responsive } from "../theme/styles"
import { getImage } from "gatsby-plugin-image"

const Grid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(auto-fit, 85%);
gap: 25px;
width: 100%;
justify-content: center;
`}
  ${Responsive.md`
justify-content: flex-start;
grid-template-columns: repeat(auto-fit, 275px);
`}
  ${Responsive.lg`
justify-content: flex-start;
grid-template-columns: repeat(auto-fit, 295px);
`}
${Responsive.xlg`
justify-content: flex-start;
grid-template-columns: repeat(auto-fit, 370px);
`}
`

const BlogLayout = styled(Row)`
  ${Responsive.sm`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 30px 0 0 0;
  `}
  ${Responsive.lg`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  `}
`

const Blog = ({ data }) => {
  return (
    <Layout title="CheshTech Blog" type="Blog">
      <Seo
        title="CheshTech Blog: Tips to Grow Your Company"
        description="The CheshTech blog offers tips for companies looking to acquire more leads and increase their revenue."
      />
      <Wrapper white>
        <Container>
          <BlogContent data={data} />
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default Blog

const BlogContent = ({ data }) => {
  return (
    <Column width="100%" margin="0 auto">
      <SEOTitle>Looking for tips to grow your business?</SEOTitle>
      <Text black margin="15px 0 0 0">
        Check out our blog posts for all things product development, digital
        marketing, and web & app development
      </Text>
      <BlogLayout>
        <Grid>
          {data.allWpPost.nodes.map(node => {
            const WpImage = getImage(
              node.featuredImage.node.localFile.childImageSharp.gatsbyImageData
            )
            const blogUrl = node.slug
            return (
              <BlogCard
                type="blogPage"
                node={node}
                WpImage={WpImage}
                blogUrl={blogUrl}
              />
            )
          })}
        </Grid>
        <Sidebar type="blog" margintop />
      </BlogLayout>
    </Column>
  )
}

export const pageQuery = graphql`
  query {
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { name: { eq: "blog" } } } } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        excerpt
        title
        uri
        slug
        date(formatString: "MM/DD/Y")
        featuredImage {
          node {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  height: 500
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
      }
    }
  }
`
